import { useEffect } from "react";
import ListItem from "../components/ListItem";
import music from '../data/interests/music.json';

function Music() {

    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (
        <div className="image-gallery">
        {music.map(album => {
            return (
                <ListItem key={`music-${album.rank}`} item={album} />
            )
        })}
        </div>
    )
}

export default Music;