import { useContext, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Modal from "./Modal";

import AppSettings from '../store/app-settings';
import { CSSTransition } from "react-transition-group";

function Project({project}) {

    const [showModal, setShowModal] = useState(false);
    const appSettings = useContext(AppSettings);
    const nodeRef = useRef(null);

    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
            <Button variant={appSettings.darkMode ? "outline-light" : "outline-dark"} onClick={() => setShowModal(true)}>
                {project.name}
            </Button>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={showModal}
                timeout={{enter: 1000, exit: 200}}
                classNames="md-modal"
                nodeRef={nodeRef}
            >
                <>
                <Modal>
                    <div className="md-content" ref={nodeRef}>
                        <h3>{project.name}</h3>
                        <div className="md-content-inner">
                            <div>
                                <div>
                                    <ul>
                                        <li><strong>Why:</strong> {project.why}</li>
                                        <hr/>
                                        <li><strong>What:</strong> {project.what}</li>
                                        <hr/>
                                        <li><strong>Extra Details:</strong> {project.extraDetails}</li>
                                        <hr/>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="md-footer">
                            <Button variant="outline-danger" onClick={closeModal}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
                <div className="md-overlay" onClick={closeModal}></div>
                </>
            </CSSTransition>
        </>
    )
}

export default Project