//import { useContext } from "react";

//import AppSettings from '../store/app-settings';

function ImageCollage({data}) {

    //const {darkMode} = useContext(AppSettings)
    
    return (
        <div className="image-grid">
            {data.map(item => {
                return (
                    <div key={item.title} className="interest-img-container">
                        <img src={item.poster} alt={item.title} />
                    </div>
                )
            })}
        </div>
    )
}

export default ImageCollage