import { useEffect } from "react";
import ListItem from "../components/ListItem";
import games from '../data/interests/games.json';

function Games() {

    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (
        <div className="image-gallery">
        {games.map(game => {
            return (
                <ListItem key={`game-${game.rank}`} item={game} />
            )
        })}
        </div>
    )
}

export default Games;