import { Routes, Route } from "react-router-dom";
import Movies from "./Movies";
import Games from "./Games";
import Music from "./Music";

function List() {
    return (
        <Routes>
            <Route path="/movies" element={<Movies />} />
            <Route path="/games" element={<Games />} />
            <Route path="/music" element={<Music />} />
        </Routes>
    )
}

export default List;