import { useContext } from 'react';
import courses from "../data/courses.json";
import CTA from '../components/CTA';

import AppSettings from '../store/app-settings';

function Courses() {

    const {darkMode} = useContext(AppSettings)
    
    return (
        <>
        <section className={darkMode ? 'dark-section' : ''}>
            <h2>Courses</h2>
            <hr/>
            <div className="container">
                {courses.map(course => {
                    return (
                        <div key={course.company}>
                        <div className="row" style={{marginBottom: "30px", padding: "0 20px"}}>
                            <div className="col-lg-4 col-md-12">
                                <a href={course.companyLink} target="_blank" rel="noreferrer">
                                    <img className={darkMode ? (course.isDark ? "company-logo" : "company-logo light-logo") : course.isDark ? "company-logo light-logo" : "company-logo"} src={course.companyLogo} style={{width: "100%"}} alt={course.company} />
                                </a>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div>
                                    <h1>{course.company}</h1>
                                    <p>{course.companyDesc}</p>
                                </div>
                                <br/>
                                {course.projects &&
                                <div className="course-table">
                                    <table style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                        <th>Course</th>
                                        <th>Project</th>
                                        <th>Code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {course.projects.map(project => {
                                            return (
                                                <tr key={`${course.company}-${project}-${project.course}`}>
                                                    <td>{project.course}</td>
                                                    <td><a href={project.link} target="_blank" rel="noreferrer">{project.project}</a></td>
                                                    <td><a href={project.repo} target="_blank" rel="noreferrer">Github</a></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    </table>
                                </div>
                                }
                                {course.courses && 
                                <div>
                                    <p>Courses Completed</p>
                                    <ul>
                                        {course.courses.map(course => {
                                            return (
                                                <li key={`${course.company}-${course}-${course.title}`}>
                                                    <a href={course.link} target="_blank" rel="noreferrer">{course.title}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                }
                            </div>
                        </div>
                        <hr/>
                        </div>
                    )
                })}
            </div>
        </section>
        <hr/>
        <CTA 
            heading="Interests" 
            text="Discover a bit more about me through my interests and the things I like most."
            link="/interests"
            image="./love.png"
        />
        <div style={{marginBottom: '50px'}}></div>
        </>
    )
}

export default Courses;