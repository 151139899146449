import { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Modal from "./Modal";
import styles from "./ListItem.module.css";

function ListItem({item}) {

    const [showModal, setShowModal] = useState(false);
    const nodeRef = useRef(null);
    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
        <div className="gallery-item" onClick={() => setShowModal(true)} >
            <img className={styles["image-gallery-img"]} src={item.poster} alt={item.title} />
            <div className="rank">
                {item.rank}
            </div>
        </div>

        <CSSTransition
            mountOnEnter
            unmountOnExit
            in={showModal}
            timeout={{enter: 1000, exit: 200}}
            classNames="md-modal"
            nodeRef={nodeRef}
        >
            <>
            <Modal>     
                <div className="md-content" ref={nodeRef}>
                    <h3>{item.title}</h3>
                    <div className="md-content-inner">
                        <div>
                            <div style={{textAlign: "center", marginBottom: "10px"}}>
                                <img style={{width: "50%"}} src={item.poster} alt={item.title} />
                            </div>
                            <p>{item.review}</p>
                            <hr/>
                        </div>
                    </div>
                    <div className="md-footer">
                        <Button variant="outline-danger" onClick={closeModal}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
            <div className="md-overlay" onClick={closeModal}></div>
            </>

        </CSSTransition>
        </>
    )
}

export default ListItem;