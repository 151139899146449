import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Carousel } from 'react-bootstrap';
import menus from '../data/menu.json';
import Intro from '../components/Intro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AppSettings from '../store/app-settings';

function Home() {

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [animating, setAnimating] = useState(true);
    
    const appSettings = useContext(AppSettings)

    useEffect(() => {
        setTimeout(() => {
            setAnimating(false)
            appSettings.pageVisited()
        }, 5000)
    })

    function renderButton(button,index) {
        switch(button.type){
            case 'link':
                return (
                    <Link key={`menu-button-${index}`} to={button.link}>
                        <Button variant="secondary" style={{minWidth: "200px"}}>
                            <div className="button-with-icon">
                                {button.icon && <FontAwesomeIcon icon={button.icon}/>}
                                <span>{button.linkLabel}</span>
                            </div>
                        </Button>
                    </Link>
                )
            case 'mail':
                return (
                    <a key={`menu-button-${index}`} href={button.link}>
                        <Button variant="secondary" style={{minWidth: "200px"}}>
                            <div className="button-with-icon">
                                <FontAwesomeIcon icon="fa-solid fa-envelope"/>
                                <span>{button.linkLabel}</span>
                            </div>
                        </Button>
                    </a>
                )
            case 'external':
                return (
                    <a key={`menu-button-${index}`} href={button.link} target="_blank" rel="noreferrer">
                        <Button variant="secondary" style={{minWidth: "200px"}}>
                            <div className="button-with-icon">
                                <FontAwesomeIcon icon="fa-brands fa-linkedin"/>
                                <span>{button.linkLabel}</span>
                            </div>
                        </Button>
                    </a>
                )
            default: 
                return;
        }
    }

    return (
        <>
        {animating && appSettings.siteVisits === 0 ?
        <Intro />
        :
        <div className="home-page">
            <Carousel 
                activeIndex={carouselIndex} 
                slide={false} 
                indicators={false} 
                controls={false}
            >
                {menus.map((menu, index) => {
                    return (
                        <Carousel.Item key={"menu-slide-" + index}>
                            <div className='carousel-item-content'>
                                <div className="carousel-image-container">
                                    <img className="carousel-image" src={menu.image} alt={menu.label} />
                                </div>
                                <div className="carousel-text-container">
                                    <h3>{menu.label}</h3>
                                    <p>{menu.description}</p>
                                    {menu.buttons.map((button,index) => {
                                        return (
                                            renderButton(button,index)
                                        )
                                    })}
                                </div>
                            </div>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
            <div className="container">
                <div className="row menu rounded">
                {menus.map((menu, index) => {
                    const active = (index === carouselIndex ? 'menu-active' : '');
                    const roundedStart = (index === 0 ? 'rounded-start' : '');
                    const roundedEnd = (index === menus.length - 1 ? 'rounded-end' : '');
                    return (
                        <div key={"menu-" + index} className={`menu-item col-md-3 ${active} ${roundedStart} ${roundedEnd}`}>
                            <button onClick={(e) => {
                                            setCarouselIndex(index); 
                                            e.preventDefault()
                            }}>
                                {menu.label}
                            </button>
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default Home;