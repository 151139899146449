import { useContext, useEffect } from 'react';
import Employer from '../components/Employer';
import Client from '../components/Client';
import CTA from '../components/CTA';

import employers from '../data/employers.json';
import projects from '../data/projects.json';
import skills from '../data/skills.json';
import software from '../data/software.json';

import AppSettings from '../store/app-settings';

function WorkExperience() {

    const appSettings = useContext(AppSettings)

    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (
        <>
        <section className={appSettings.darkMode ? 'dark-section' : ''}>
            <h2>Previous Employers</h2>
            <hr />
            {employers.map((employer,index) => {
                return (
                    <Employer key={`employer-${employer.name}-${index}`} company={employer} />
                )
            })}
        </section>

        <section className={appSettings.darkMode ? 'dark-section' : ''}>
            <h2 style={{textAlign: "center"}}>Client Projects</h2>
            <hr/>
            <div className='client-grid'>
                {projects.map((project,index) => {
                    return (
                        <Client key={`client-${index}`} company={project} />
                    )
                })}
            </div>
            <br/>
        </section>

        <section className={appSettings.darkMode ? 'dark-section' : ''}>
            <h2>Skills and Technologies</h2>
            <hr/>
            <div className='skills-grid'>
                <div>
                    <h3>Skills</h3>
                    <p>I consider myself an easy going individual who will work my socks off when needed. These are just a few of my best traits...</p>
                    <ul>
                    {skills.map(skill => {
                        return (
                            <li key={skill.title}>
                                <strong>{skill.title}</strong> - {skill.description}
                            </li>
                        )
                    })}
                    </ul>
                </div>
                <div>
                    <h3>Programs/Software</h3>
                    <p>A list of programming languages and software that I've had some good experience with. Always willing to learn more.</p>
                    <ul>
                    {software.map(app => {
                        return (
                            <li key={app.title}>
                                <strong>{app.title}</strong> - {app.description}
                            </li>
                        )
                    })}
                    </ul>
                </div>
            </div>
            <br/>
        </section>
        <hr/>
        <CTA 
            heading="Get To Know Me" 
            text="If you haven't already, head on over to the About Me page to get some more details about the person behind this site"
            link="/about"
            image="./about.png"
        />
        <div style={{marginBottom: '50px'}}></div>
        {/* <div className="md-overlay"></div> */}
        </>
    )
}

export default WorkExperience;