import { useContext, useEffect } from 'react';

import AppSettings from '../store/app-settings';
import Interest from '../components/Interest';
import movies from "../data/interests/films.json";
import games from "../data/interests/games.json";
import music from "../data/interests/music.json";

function Interests() {
    const appSettings = useContext(AppSettings)

    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (
        <>
        <section className={appSettings.darkMode ? 'dark-section' : ''}>
            <div className="interest-container">
                <Interest key="movies" title="Movies" route="/interests/movies" data={movies} />
                <Interest key="games" title="Games" route="/interests/games" data={games} />
                <Interest key="music" title="Music" route="/interests/music" data={music} />
            </div>
        </section>
        </>
    )
}

export default Interests;