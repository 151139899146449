import { useContext, useEffect } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import CTA from '../components/CTA';
import stops from '../data/journey-stops.json';
import hobbies from '../data/hobbies.json';
import { Link } from 'react-router-dom';

import AppSettings from '../store/app-settings';

function About() {

    const {darkMode} = useContext(AppSettings)
    
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (
        <>
        <section className={darkMode ? "about-intro-dark" : "about-intro"} style={{display: "flex"}}>
            <img src="./hello.png" alt="me" width="300px" /> 
            <div className="about-intro-text">
                <h3>Welcome!</h3>
                <p>So you've made it past my home screen, hopefully that means you want to know a little bit about me.</p>
                <p>Explore this page to learn about where I've come from, where I've lived and the sorts of things that I'm into.</p>
            </div>
        </section>
        <section className={darkMode ? 'dark-section' : ''}>
            <h2>My Journey</h2>
            <hr/>
            <div>
                {stops.map((stop,index) => {
                    return (
                        <div key={`${stop.place}-${index}`}>
                        <div className="journey-stop">
                            <div>
                                <a href={stop.imageLink} target="_blank" rel="noreferrer">
                                    <img src={stop.image} alt={stop.place} width="200px" height="200px" style={{objectFit: "contain", margin: "20px"}} /> 
                                </a>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", padding: "20px 40px"}}>
                                <h3>{stop.place} ({stop.dates})</h3>
                                <p>{stop.details}</p>
                            </div>
                        </div>
                        <hr style={{width: "40%", margin: "auto"}} />
                        </div>
                    )
                })}
            </div>
        </section>
        <section id="hobbies" className={darkMode ? 'dark-section' : ''}>
            <h2>Hobbies</h2>
            <hr/>
            <Carousel>
                {hobbies.map((hobby, index) => {
                    return (
                        <Carousel.Item key={`hobby-${index}`}>
                            <div className='carousel-item-content'>
                                <div className="carousel-image-container">
                                    <img className="carousel-image" src={hobby.image} alt={hobby.label} /> 
                                </div>
                                <div className="carousel-text-container">
                                    <h4>{hobby.title}</h4>
                                    <p>{hobby.content}</p>
                                    {hobby.link && 
                                    <Link to={`/lists/${hobby.title.toLowerCase()}`}>
                                        <Button variant={darkMode ? 'outline-light' : 'outline-dark'}>
                                            My Top 10
                                        </Button>
                                    </Link>
                                }
                                </div>
                            </div>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </section>
        <hr/>
        <CTA 
            heading="Work Experience" 
            text="Good work, you've made it to the end of this page, if you want to find out even more, and learn about my working life, then head on over to my Work Experience page. See the roles I've had and the skills I've picked up so far."
            link="/work-experience"
            image="./work.png"
        />
        <br/>
        <br/>
        </>
    )
}

export default About;