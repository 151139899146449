function NotFound() {
    return (
        <div className="container" style={{height: "90vh"}}>
            <div className="row" style={{height: "100%"}}>
                <div className="col-6" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div className="title-text">
                        <h1>404</h1>
                        <h2>PAGE NOT FOUND</h2>
                    </div>
                    <div className="gears" id="two-gears">
                        <div className="gears-container">
                            <div className="gear-rotate"></div>
                            <div className="gear-rotate-left"></div>
                        </div>
                    </div>
                </div>
                <div className="col-6" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <img src="/shrug.png" alt="thinking" />
                </div>
            </div>
        </div>
    )
}

export default NotFound;