import { useContext } from 'react';
import styles from './Footer.module.css';

import AppSettings from '../store/app-settings';

function Footer() {

    const appSettings = useContext(AppSettings)
    const date = new Date();

    return (
        <footer className={`${styles.footer} ${appSettings && styles.dark}`}>
            © James Derrick {date.getFullYear()}
        </footer>
    )
}

export default Footer;