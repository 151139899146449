import { createContext, useState, useEffect } from "react";

const SettingsContext = createContext({
    darkMode: false,
    siteVisits: 0
})

export function SettingsContextProvider({children}) {

    const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [siteVisits, setSiteVisits] = useState(0);

    useEffect(() => {
        if(darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark')
        }
    },[darkMode])

    function pageVisited() {
        setSiteVisits(prev => prev + 1);
    }

    function toggleDarkMode() {
        setDarkMode(prev => !prev)
    }

    const context = {
        darkMode: darkMode,
        siteVisits: siteVisits,
        pageVisited: pageVisited,
        toggleDarkMode: toggleDarkMode
    }

    return <SettingsContext.Provider value={context}>
        {children}
    </SettingsContext.Provider>
}

export default SettingsContext