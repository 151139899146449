// import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// import AppSettings from '../store/app-settings';

function MyNavbar() {

  // const appSettings = useContext(AppSettings)

  return (
    <Navbar bg="dark" variant="dark" expand="sm" sticky="top">
      <Container>
        <Navbar.Brand href="/">Tiny Jim</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} exact="true" to="/about">About Me</Nav.Link>
            <Nav.Link as={NavLink} exact="true" to="/work-experience">Work Experience</Nav.Link>
            <Nav.Link as={NavLink} exact="true" to="/courses">Courses</Nav.Link>
            <NavDropdown title="Interests" id="interest-dropdown">
              <NavDropdown.Item href="/interests/movies">Movies</NavDropdown.Item>
              <NavDropdown.Item href="/interests/games">Games</NavDropdown.Item>
              <NavDropdown.Item href="/interests/music">Music</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link onClick={() => appSettings.toggleDarkMode()}>
              {appSettings.darkMode ? "Light" : "Dark"} Mode
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;