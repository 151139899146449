import { useContext } from "react";
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import ImageCollage from "./ImageCollage"

import AppSettings from '../store/app-settings';

function Interest({title, route, data}) {

    const {darkMode} = useContext(AppSettings)
    
    return (
        <div className="interest">
            <h3>{title}</h3>
            <ImageCollage data={data} />
            <Link to={route}>
            <Button variant={darkMode ? "outline-light" : "outline-dark"}>
                View
            </Button>
            </Link>
        </div>
    )
}

export default Interest