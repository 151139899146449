import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import WorkExperience from './pages/WorkExperience';
import Courses from './pages/Courses';
import List from './pages/List';
import NotFound from "./pages/NotFound";
import MyNavbar from './layout/Navbar';
import Footer from './layout/Footer';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faFilm, faMusic, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Interests from "./pages/Interests";

library.add(faEnvelope, faFilm, faLinkedin, faMusic, faGamepad)

function App() {
  return (
    <div className="App">
      <MyNavbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="work-experience" element={<WorkExperience />} />
          <Route path="courses" element={<Courses />} />
          <Route path="interests" element={<Interests />} />
          <Route path="interests/*" element={<List />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
