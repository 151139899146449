import { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import styles from "./CTA.module.css";

import AppSettings from '../store/app-settings';

function CTA({heading, text, link, image}) {

    const {darkMode} = useContext(AppSettings)

    return (
        <div className={styles["cta-container"]}>
            <img src={image} alt="cta" />
            <div className={styles["cta-content"]}>
                <h2>{heading}</h2>
                <p>{text}</p>
                <Link to={link}>
                    <Button variant={darkMode ? "outline-light" : "outline-dark"}>
                        Read More
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default CTA;