import { useContext, useState, useRef } from "react";
import Modal from "./Modal";
import { Button } from "react-bootstrap";
import styles from "./Employer.module.css";
import { CSSTransition } from "react-transition-group";

import AppSettings from '../store/app-settings';

function Employer({company}) {

    const {darkMode} = useContext(AppSettings)
    const nodeRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    function closeModal() {
        setShowModal(false);
    }

    return (
        <>
        <div className={styles["company-container"]}>
            <a href={company.link} target="_blank" rel="noreferrer">
                <div className={styles["image-container"]}>
                    {company.dark ?
                    <img className={darkMode ? '' : styles["dark-image"]} src={company.logo} alt={`${company.name} logo`} />
                    :
                    <img className={darkMode ? styles["light-image"] : ''} src={company.logo} alt={`${company.name} logo`} />
                    }   
                </div>
            </a>
            <div className={styles["company-details"]}>
                <h1>{company.name}</h1>
                <p>{company.description}</p>
                <Button variant={darkMode ? "outline-light" : "outline-dark"} onClick={() => setShowModal(true)}>
                    View Roles
                </Button>
            </div>
        </div>

        <CSSTransition
            mountOnEnter
            unmountOnExit
            in={showModal}
            timeout={{enter: 1000, exit: 200}}
            classNames="md-modal"
            nodeRef={nodeRef}
        >
        <>
        <Modal>     
            <div className="md-content" ref={nodeRef}>
                <h3>{company.name}</h3>
                <div className="md-content-inner">
                    <div>
                        {company.roles.map(role => {
                            return (
                                <div key={`${company.name}-${role.title}`}>
                                    <h2>{role.title}</h2>
                                    <ul>
                                        <li>Start: {role.start}</li>
                                        <li>End: {role.end}</li>
                                        <li>Responsibilities: {role.responsibilities}</li>
                                    </ul>
                                    <hr/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="md-footer">
                    <Button variant="outline-danger" onClick={closeModal}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
        <div className="md-overlay" onClick={closeModal}></div>
        </>
        </CSSTransition>
        </>
    )
}

export default Employer