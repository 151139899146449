function Intro() {
    return (
        <div className="animation-container">
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell size-16"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-26"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell size-16"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-26"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell size-5"></div>
                <div className="cell size-4 yellow"></div>
                <div className="cell size-5"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-28"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3 purple"></div>
                <div className="cell purple"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell size-4 red"></div>
                <div className="cell size-8"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3 purple"></div>
                <div className="cell purple"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell yellow"></div>
                <div className="cell yellow"></div>
                <div className="cell"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-10"></div>
                <div className="cell red"></div>
                <div className="cell"></div>
                <div className="cell red"></div>
                <div className="cell size-6"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell size-8"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell blue"></div>
                <div className="cell size-12"></div>
                <div className="cell red"></div>
                <div className="cell size-2"></div>
                <div className="cell red"></div>
                <div className="cell size-5"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell"></div>
                <div className="cell yellow"></div>
                <div className="cell"></div>
                <div className="cell blue"></div>
                <div className="cell"></div>
                <div className="cell blue"></div>
                <div className="cell size-4"></div>
                <div className="cell blue"></div>
                <div className="cell"></div>
                <div className="cell blue"></div>
                <div className="cell size-6"></div>
                <div className="cell red"></div>
                <div className="cell size-2"></div>
                <div className="cell red"></div>
                <div className="cell size-4"></div>
            </div>
            <div className="animation-row">
                <div className="cell"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell purple"></div>
                <div className="cell size-3"></div>
                <div className="cell size-5 yellow"></div>
                <div className="cell size-4"></div>
                <div className="cell size-6 blue"></div>
                <div className="cell size-6"></div>
                <div className="cell size-6 blue"></div>
                <div className="cell size-8"></div>
                <div className="cell size-4 red"></div>
                <div className="cell size-6"></div>
            </div>
            
  </div>
    )
}

export default Intro