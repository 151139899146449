import { useContext } from "react";
import Project from "./Project";
import styles from "./Client.module.css";

import AppSettings from '../store/app-settings';

function Client({company}) {

    const {darkMode} = useContext(AppSettings)

    return (
        <div className={styles["client-container"]}>
            <a href={company.companyURL} target="_blank" rel="noreferrer">
                <div className={styles["image-container"]}>
                    <img className={darkMode ? styles["light-image"] : company.logoBackground ? styles["dark-image"] : ''} src={company.companyLogo} alt={company.companyName} />
                </div>
            </a>
            <div className={styles["project-links"]}>
                {company.projectDetails.map((project,index) => {
                    return (
                        <Project key={`project-link-${index}`} project={project} />
                    )
                })}
            </div>
        </div>
    )
}

export default Client;